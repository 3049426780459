import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowLeft,
  faArrowsAlt,
  faBaseballBall,
  faCaretDown,
  faCaretUp,
  faCheck,
  faCircle,
  faExchangeAlt,
  faMinus,
  faPlus,
  faSpinner,
  faSquare,
  faSync,
  faTimes,
  faTimesCircle,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faArrowLeft,
  faArrowsAlt,
  faBaseballBall,
  faCaretDown,
  faCaretUp,
  faCheck,
  faCircle,
  faExchangeAlt,
  faMinus,
  faPlus,
  faSpinner,
  faSquare,
  faSync,
  faTimes,
  faTimesCircle,
  faUser,
  faUsers
)
