import { observable, computed, action, decorate } from 'mobx'
import axios from 'axios'
import { v4 as uuid } from 'uuid'

const fetch = (options) => axios(options).then((r) => r.data)

const Role = {
  ADMIN: 'ADMIN',
  SUPER_USER: 'SUPER_USER',
}

export default class AuthStore {
  constructor(store) {
    this.store = store
    this.initialize()
  }

  user = null

  get username() {
    return this.user ? this.user.username : null
  }

  get role() {
    return this.user ? this.user.role : null
  }

  get isAuthenticated() {
    return !!this.role
  }

  get isAdmin() {
    return this.role === Role.ADMIN
  }

  get isSuperUser() {
    let supers = [
      'chris.lentine@mlb.com',
      'lawrence.fischer@mlb.com',
      'matt.graser@mlb.com',
      'alex.weber-shapiro@mlb.com',
      'rob.engel@mlb.com',
      'greg.sawers@mlb.com',
      'mlb-api-user',
      'tyler.barton@mlb.com',
      'charles.miller@mlb.com',
      'jonathan.libman@mlb.com',
      'derrick.miller@mlb.com',
      'sarah.johnson@mlb.com',
      'micah.parshall@mlb.com',
      'tom.wetzel@mlb.com',
      'jason.koehn@mlb.com',
      'stephen.gross@mlb.com',
      'josh.cookson@mlb.com',
    ]

    return (
      [Role.ADMIN, Role.SUPER_USER].includes(this.role) ||
      supers.includes(this.username)
    )
  }

  // requests

  _requests = {}

  get isLoading() {
    return Object.keys(this._requests).length > 0
  }

  getSession() {
    let id = uuid()

    this._requests[id] = true

    fetch({
      url: '/session',
    })
      .then(
        action((user) => {
          if (user && user.role) {
            this.user = user
          }
        })
      )
      .finally(
        action(() => {
          delete this._requests[id]
        })
      )
  }

  login(data) {
    let id = uuid()
    this._requests[id] = true

    fetch({
      url: '/login',
      method: 'POST',
      data: data,
    })
      .then(
        action((user) => {
          if (user && user.role) {
            this.user = user
            this.store.router.push(this.store.referrer)
          }
        })
      )
      .catch(
        action((error) => {
          this.showAlert()
          console.error(error)
        })
      )
      .finally(
        action(() => {
          delete this._requests[id]
        })
      )
  }

  logout() {
    let id = uuid()
    this._requests[id] = true

    fetch({
      url: '/logout',
      method: 'POST',
      data: {},
    })
      .then(
        action((user) => {
          this.user = user
        })
      )
      .finally(
        action(() => {
          delete this._requests[id]
        })
      )
  }

  alert = false

  showAlert() {
    this.alert = true
  }

  hideAlert() {
    this.alert = false
  }

  toggleAlert() {
    this.alert = !this.alert
  }

  initialize() {
    // this.authReaction = reaction(
    //     () => ({
    //         isAuthenticated: this.isAuthenticated,
    //         isLoginPage: this.store.isLoginPage,
    //     }),
    //     ({
    //         isAuthenticated,
    //         isLoginPage,
    //     }) => {
    //         if (isAuthenticated && isLoginPage){
    //             this.store.router.push(this.store.referrer)
    //         }
    //     }
    // )
  }
}

decorate(AuthStore, {
  user: observable,
  username: computed,
  role: computed,
  isAuthenticated: computed,
  isAdmin: computed,
  isSuperUser: computed,
  isLoading: computed,
  _requests: observable,
  getSession: action,
  login: action,
  logout: action,
  alert: observable,
  showAlert: action,
  hideAlert: action,
  toggleAlert: action,
})
