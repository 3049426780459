export const GAMEDAY_ASSET_BASE_URL =
  'https://prod-gameday.mlbstatic.com/responsive-gameday-assets/1.3.0'

export const GameType = {
  SPRING_TRAINING: 'S',
  EXHIBITION: 'E',
}

export const NOTIFICATION_TYPES = {
  DUPLICATE_OPERATOR: 'DUPLICATE_OPERATOR',
  CONNECTION_ERROR: 'CONNECTION_ERROR',
  SESSION_DISCONNECTED: 'SESSION_DISCONNECTED',
  REMOTE_UPDATE: 'REMOTE_UPDATE',
  UPDATE_SENT: 'UPDATE_SENT',
}

export const POSITIONS = {
  1: 'Pitcher',
  2: 'Catcher',
  3: '1st Base',
  4: '2nd Base',
  5: '3rd Base',
  6: 'Shortstop',
  7: 'Left Field',
  8: 'Center Field',
  9: 'Right Field',
  10: 'Designated Hitter',
  11: 'Pinch Hitter',
  12: 'Pinch Runner',
  13: 'Extra Hitter',
}

export const TYPES = {
  PLAYER: 'PLAYER',
  POSITION: 'POSITION',
}

export const EVENTS = {
  BALL: 'ball',
  STRIKE: 'strike',
  FOUL: 'foul',
  AUTO_BALL: 'auto_ball',
  AUTO_STRIKE: 'auto_strike',
  PICKOFF: 'pickoff',
  IN_PLAY: 'in_play',
  OUT: 'out',
  HIT: 'hit',
  RUN: 'run',
  ERROR: 'error',
  NEXT_BATTER: 'next_batter',
  PITCHER_ON_RUBBER: 'pitcher_on_rubber',
  RUNNER_MOVEMENT: 'runner_movement',
  END_RUNNER_MOVEMENT: 'end_runner_movement', // this event is never sent; just used to distinguish btwn start and end
  TIMEOUT: 'timeout',
  NEXT_FRAME: 'next_frame',
  END_OF_PLAY: 'end_of_play',
  INTENTIONAL_WALK: 'intentional_walk',
  HIT_BY_PITCH: 'hit_by_pitch',
  GAME_OVER: 'game_over',
  SUBSTITUTION: 'substitution',
  CORRECTION: 'correction',
  END_CORRECTION: 'end_correction',
  HOME_RUN: 'home_run',

  /* NGE-exclusive events */
  CALLED_STRIKE: 'called_strike',
  SWINGING_STRIKE: 'swinging_strike',
  UNKNOWN_STRIKE: 'unknown_strike',
  SINGLE: 'single',
  DOUBLE: 'double',
  TRIPLE: 'triple',
}

export const CODES = {
  b: EVENTS.BALL,
  s: EVENTS.STRIKE,
  f: EVENTS.FOUL,
  v: EVENTS.AUTO_BALL,
  k: EVENTS.AUTO_STRIKE,
  z: EVENTS.PICKOFF,
  p: EVENTS.IN_PLAY,
  o: EVENTS.OUT,
  h: EVENTS.HIT,
  r: EVENTS.RUN,
  e: EVENTS.ERROR,
  n: EVENTS.NEXT_BATTER,
  t: EVENTS.PITCHER_ON_RUBBER,
  m: EVENTS.RUNNER_MOVEMENT,
  f1: EVENTS.HIT_BY_PITCH,
  f4: EVENTS.INTENTIONAL_WALK,
  c: EVENTS.CALLED_STRIKE,
  d: EVENTS.DOUBLE,
  q: EVENTS.TRIPLE,
}

export function getInitialGameState() {
  return {
    _bossState: null,
    gamedayLineupsEnabled: true,
    gumboState: {},
    eventName: '',

    // boss game state

    balls: 0,
    strikes: 0,
    outs: 0,
    inning: 1,
    isTopInning: true,
    scheduledInnings: 9,
    pitchNumber: 0,

    awayPitchCount: 0,
    homePitchCount: 0,
    hitsAway: 0,
    errorsAway: 0,
    hitsHome: 0,
    errorsHome: 0,
    atBatNumber: 1,
    pickoffNumber: 0,

    awayBatterIdx: 0,
    awayPitcherId: null,
    homeBatterIdx: 0,
    homePitcherId: null,
    baseRunners: [false, false, false],
    pitcherOnRubber: false,
    currentBatSide: 'R',
    gameMode: null,

    lastEvent: null,
    previousLastEvent: null,

    linescore: new Array(9).fill({
      runsAway: null,
      runsHome: null,
    }),

    awayTeam: {},
    homeTeam: {},

    awayRoster: [],
    homeRoster: [],

    awayLineup: [],
    homeLineup: [],

    awayPositions: [],
    homePositions: [],

    status: {},
    venue: {},
    datetime: {},

    //Audit Log
    eventsThisAtBat: [],

    pitchData: null,

    isCorrecting: false,
    isCorrection: false,
    isObserving: false,
    isPrivate: false,
    isTimeout: false,
    runnerMovement: false,
    scheduleEventType: null,
    showBattingTeamPitcher: false,
    showDefense: false,
    _nextBatterHotkeyEnabled: true,
    _nextFrameHotkeyEnabled: false,
  }
}
