import axios from 'axios'
import { get } from 'lodash'
import { action, computed, decorate, observable, reaction } from 'mobx'
import moment from 'moment'
import { v4 as uuid } from 'uuid'

const fetch = (options) => axios(options).then((r) => r.data)

export default class ScheduleStore {
  constructor(store) {
    this.store = store
    this.initialize()
  }

  sportId = '1'
  leagueId = ''
  date = moment()
  gamePks = ''
  gamePkSearchValue = ''
  tab = 'games'
  games = []
  events = []
  leagues = []

  set(key, value) {
    this[key] = value
  }

  sports = [
    {
      value: '1,11,12,13,14,15,16,17,22,586,51,509,23,21',
      label: 'All',
    },
    {
      value: 1,
      label: 'MLB',
    },
    {
      value: '11,12,13,14,15,16,17,21',
      label: 'Minors',
    },
    {
      value: 11,
      label: 'AAA',
    },
    {
      value: 12,
      label: 'AA',
    },
    {
      value: 13,
      label: 'A+',
    },
    {
      value: 14,
      label: 'A',
    },
    {
      value: 15,
      label: 'A (Short)',
    },
    {
      value: 16,
      label: 'Rookie',
    },
    {
      value: 17,
      label: 'Offseason',
    },
    {
      value: 22,
      label: 'College Baseball',
    },
    {
      value: 586,
      label: 'High School Baseball',
    },
    {
      value: 51,
      label: 'International Baseball',
    },
    {
      value: 509,
      label: 'International Baseball (18U)',
    },
    {
      value: 23,
      label: 'Independent',
    },
  ]

  fetch() {
    let id = uuid()

    this._requests[id] = true
    this.games.clear()

    return fetch({
      url: '/api/schedule',
      params: this.params,
    })
      .then(
        action((data) => {
          const { games = [], events = [] } = data
          this.games.replace(
            games.filter((game) => get(game, 'status.codedGameState') !== 'C')
          )
          this.events.replace(events)
        })
      )
      .catch((error) => {
        console.error(error)
      })
      .finally(
        action(() => {
          delete this._requests[id]
        })
      )
  }

  fetchLeagues() {
    let id = uuid()

    this._requests[id] = true
    this.leagues.clear()

    return fetch({
      url: '/api/schedule/leagues',
      params: { sportId: this.sportId },
    })
      .then(
        action((leagues = []) => {
          this.leagues.replace(leagues)
        })
      )
      .catch((error) => {
        console.error(error)
      })
      .finally(
        action(() => {
          delete this._requests[id]
        })
      )
  }

  _requests = {}

  get isLoading() {
    return Object.keys(this._requests).length > 0
  }

  get shouldFetch() {
    return this.store.auth.isAuthenticated
  }

  get params() {
    let { date, sportId, gamePks, leagueId } = this

    return {
      date: date.format('YYYY-MM-DD'),
      sportId,
      leagueId,
      gamePks,
    }
  }

  initialize() {
    if (this.shouldFetch) {
      this.fetch()
      this.fetchLeagues()
    }

    reaction(
      () => ({
        params: this.params,
        shouldFetch: this.shouldFetch,
      }),
      ({ shouldFetch }) => {
        if (shouldFetch) {
          this.fetch()
        }
      }
    )

    reaction(
      () => ({
        sportId: this.sportId,
        shouldFetch: this.shouldFetch,
      }),
      ({ shouldFetch }) => {
        if (shouldFetch) {
          this.fetchLeagues()
        }
      }
    )
  }
}

decorate(ScheduleStore, {
  sportId: observable,
  leagueId: observable,
  date: observable,
  gamePks: observable,
  gamePkSearchValue: observable,
  tab: observable,
  games: observable,
  events: observable,
  leagues: observable,
  _requests: observable,
  fetch: action,
  shouldFetch: computed,
  params: computed,
  set: action,
})
