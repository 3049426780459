import { createBrowserHistory } from 'history'
import { Provider } from 'mobx-react'
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router'
import React, { Component } from 'react'
import Loadable from 'react-loadable'
import { Route, Router, Switch } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'
import AppStore from './stores/AppStore'
import './styles/index.scss'
import './styles/pfx.css'
import './font-awesome'

const browserHistory = createBrowserHistory()
const router = new RouterStore()

const history = syncHistoryWithStore(browserHistory, router)

const store = new AppStore(router)

class App extends Component {
  componentDidMount() {
    store.auth.getSession()
  }

  render() {
    return (
      <Router history={history}>
        <Provider store={store}>
          <ToastProvider placement='bottom-left' autoDismiss>
            <div className='App'>
              <Route
                path='*'
                exact
                component={Loadable({
                  loader: () =>
                    import(
                      /* webpackChunkName: "Navbar" */ `./components/Navbar.js`
                    ),
                  loading: Loading,
                })}
              />

              <Route
                path='*'
                exact
                component={Loadable({
                  loader: () =>
                    import(
                      /* webpackChunkName: "Notification" */ `./components/Notification.js`
                    ),
                  loading: Loading,
                })}
              />

              <Switch>
                <Route
                  exact
                  path='/'
                  component={Loadable({
                    loader: () =>
                      import(
                        /* webpackChunkName: "Schedule" */ `./pages/Schedule.js`
                      ),
                    loading: Loading,
                  })}
                />

                <Route
                  exact
                  path='/events/:eventId'
                  component={Loadable({
                    loader: () =>
                      import(/* webpackChunkName: "Game" */ `./pages/Game.js`),
                    loading: Loading,
                  })}
                />

                <Route
                  exact
                  path='/games/:gamePk'
                  component={Loadable({
                    loader: () =>
                      import(/* webpackChunkName: "Game" */ `./pages/Game.js`),
                    loading: Loading,
                  })}
                />

                <Route
                  exact
                  path='/login'
                  component={Loadable({
                    loader: () =>
                      import(
                        /* webpackChunkName: "Login" */ `./pages/Login.js`
                      ),
                    loading: Loading,
                  })}
                />

                <Route
                  exact
                  path='/login/basic'
                  component={Loadable({
                    loader: () =>
                      import(
                        /* webpackChunkName: "Login" */ `./pages/LoginBasic.js`
                      ),
                    loading: Loading,
                  })}
                />

                <Route
                  path='*'
                  component={Loadable({
                    loader: () =>
                      import(
                        /* webpackChunkName: "NotFound" */ `./pages/NotFound.js`
                      ),
                    loading: Loading,
                  })}
                />
              </Switch>

              <Route
                path='*'
                exact
                component={Loadable({
                  loader: () =>
                    import(
                      /* webpackChunkName: "Footer" */ `./components/Footer.js`
                    ),
                  loading: Loading,
                })}
              />
            </div>
          </ToastProvider>
        </Provider>
      </Router>
    )
  }
}

const Loading = () => {
  return (
    <div id='loader' className='text-mlb-grey-600'>
      <i id='spinner' className='fa fa-2x fa-spinner fa-pulse'></i>
    </div>
  )
}

export default App
