export default function popUpAlert(title, confirmButtonText) {
  return {
    title: title,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: confirmButtonText,
  }
}
