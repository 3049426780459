import { computed, decorate, reaction } from 'mobx'
import io from 'socket.io-client'
import { NOTIFICATION_TYPES } from '../constants'
let socket

export default class SocketStore {
  constructor(store) {
    this.store = store
    this.initialize()
  }

  get shouldConnect() {
    return this.store.gamePk && this.store.auth.isAuthenticated
  }

  initialize() {
    if (this.shouldConnect) {
      this.connect()
    }

    this.connectReaction = reaction(
      () => ({
        shouldConnect: this.shouldConnect,
        env: this.store.info.env,
      }),
      ({ shouldConnect, env }) => {
        if (shouldConnect) {
          this.connect()
        } else {
          this.disconnect()
        }
      }
    )
  }

  connect() {
    this.disconnect()

    let options = {
      transports: ['websocket'],
    }

    if (this.store.info.env == 'LOCAL') {
      options = 'http://localhost:3001'
    }

    socket = io(options)

    socket.on('connect', () => {
      socket.emit('join', this.store.gamePk)

      socket.on('connections', (connections) => {
        let wasConnected = !!this.store.game.isConnected
        this.store.connections.replace(connections)
        if (wasConnected && !this.store.game.isConnected) {
          this.store.flash(NOTIFICATION_TYPES.SESSION_DISCONNECTED)
        }
      })

      socket.on('pitch', (pitchData) => {
        this.store.game.set('pitchData', pitchData)
      })

      socket.on('bossState', (data) => {
        // game state from currently connected boss operator
        const { gamePk, bossState, username } = data
        const { isAdminCorrection, ...rawBossState } = bossState

        if (username == this.store.auth.username) {
          return
        }

        if (gamePk != this.store.gamePk) {
          return
        }

        if (this.store.auth.isSuperUser && !this.store.game.isCorrecting) {
          this.store.game.set('_bossState', rawBossState)
        }

        if (this.store.game.isConnected && isAdminCorrection) {
          this.store.game.receiveCorrection(rawBossState)
        }
      })
    })
  }

  disconnect() {
    if (socket && socket.connected) {
      socket.disconnect()
    }
  }
}

decorate(SocketStore, {
  shouldConnect: computed,
})
